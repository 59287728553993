<template>
  <div :class="['banner', bannerType]">
    <img
      v-bind:src="require(`@/assets/images/icons/ic_${iconName}.svg`)"
      :class="iconClass"
    />
    <div class="banner-content">
      <slot />
    </div>
    <button v-if="dismissible" class="close-button" @click="dismissBanner">
      <img
        :src="require(`@/assets/images/icons/ic_x_grey.svg`)"
      />
    </button>
    <span v-if="refreshable" class="refresh-button" @click="refreshPage">
      <u>REFRESH</u>
    </span>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    bannerType: {
      type: String,
      default: () => "reminder",
    },
    iconClass: {
      type: String,
      default: () => "reminder-icon",
    },
    iconName: {
      type: String,
      default: () => "reminder_blue",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    refreshable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dismissBanner() {
      this.$emit('dismissed');
    },
    refreshPage() {
      location.reload(true);
    },
  },
};
</script>

<style lang="scss">
.banner {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
}

.reminder {
  background-color: #e9f1ff;
  color: #0041d1;
  border-radius: 8px;
}

.alert {
  background-color: #F8EEEE;
  color: #B42318;
  border-radius: 8px;
}

.banner-content {
  flex-grow: 1;
  margin-left: 10px;
  font-size: 14px;
  line-height: 18px;

  a {
    color: #0041d1;
    text-decoration: underline;
  }
}

.reminder-icon {
  width: 18px;
  height: 20px;
}

.alert-icon {
  width: 20px;
  height: 17px;
}

.close-button {
  background: none;
  font-size: 16px;
  cursor: pointer;
  border: none;
}

.refresh-button {
  padding-left: 15px;
  cursor: pointer;
}

@media screen and(min-width: 991px) {
  .banner {
    margin-top: 0;
  }
  .banner-content {
    font-size: 16px;
  }
}
</style>
